<template>
  <van-nav-bar
    title="安全验证"
    left-text=""
    left-arrow
    @click-left="onClickLeft"
    safe-area-inset-top
    :border="false"
  />
  <div class="login-box">
    <div style="margin-top:20px">
      <van-cell-group>
        <van-field v-model="phone" placeholder="手机号" size="large" />
        <van-field
          v-model="code"
          placeholder="请输入验证码"
          size="large"
        >
          <template #button>
            <van-button
              v-if="showSend"
              size="small"
              type="primary"
              @click="handleGetVerificationCode"
              >发送验证码</van-button
            >
            <span v-else>{{ seconds }}</span>
          </template>
        </van-field>
      </van-cell-group>
      <div class="login-btn">
        <van-button
          block
          round
          :disabled="nextDisabled"
          @click="handleLinkToNext"
          type="primary"
          style="width:211px;height:48px;margin-top:40px"
          size=""
          >下一步</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { reactive, toRefs, onUnmounted } from "vue";
import { Toast } from "vant";
import { getUserVerificationCode } from "@/api/user";
export default {
  setup() {
    const router = useRouter();
    let interval = null;
    const data = reactive({
      phone: "",
      userPwd: "",
      typeId: 1,
      nextDisabled: true,
      showSend: true,
      verificationCode: "",
      code: "",
      seconds: 60,
    });
    const handleLinkToNext = () => {
      sessionStorage.setItem("verificationCode", data.code);
      sessionStorage.setItem("updatePwdPhone", data.phone);
      router.push("/updatePwd");
    };
    const onClickLeft = () => {
      router.go(-1);
    };
    const handleGetVerificationCode = () => {
      if (data.phone.length === 11) {
        getUserVerificationCode({ phone: data.phone })
          .then((result) => {
            if (result) {
              Toast.success("验证码发送成功");
              data.nextDisabled = false;
              data.showSend = false;
              interval = setInterval(() => {
                data.seconds--;
              }, 1000);
              data.verificationCode = result;
            }
          })
          .catch(() => {
            Toast({
              message: "验证码发送失败，请联系客服",
              position: "bottom",
            });
          });
      } else {
        Toast({
          message: "请输入正确的电话号码",
          position: "bottom",
        });
      }
    };
    onUnmounted(() => {
      clearInterval(interval);
    });
    return {
      handleGetVerificationCode,
      onClickLeft,
      handleLinkToNext,
      ...toRefs(data),
    };
  },
};
</script>

<style lang="scss" scoped>
.login-box {
  text-align: left;
  margin: 30px 8px;
  .login-title {
    font-weight: 600;
    font-size: 30px;
    line-height: 42px;
    color: #171725;
    margin-left: 10px;
  }
  .login-form {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .login-btn {
    display: flex;
    justify-content: center;
  }
}
</style>
